export const PARTY_CSS_CLASSES = {
  Dem: 'dem',
  GOP: 'gop',
  LMN: 'lmn',
  GRP: 'grp',
  Inp: 'ind',
  Grn: 'grn',
  DCG: 'grn',
  Lib: 'lib',
  ADP: 'adp',
  CST: 'cst',
  SWP: 'swp',
  Yes: 'yes',
  No: 'no',
  Oth: 'oth',
};

export const TOTAL_ELECTORS = 538;

export const PARTY_FULL_NAMES = {
  Dem: 'Democratic-Farmer-Labor',
  GOP: 'Republican',
  LMN: 'Legal Marijuana Now',
  GRP: 'Grassroots-Legalize Cannabis',
  Inp: 'Independence',
  Grn: 'Green Party',
  Lib: 'Libertarian Party',
  ADP: 'American Delta Party',
  CST: 'Constitution Party',
  SWP: 'Socialist Workers Party',
  Una: 'Unaffiliated',
  NP: 'Nonpartisan',
  Yes: ' ',
  No: ' ',
  Oth: ' ',
  WI: 'Write-in',
};

// Used colors that are 99% accessible from here: https://sashat.me/2017/01/11/list-of-20-simple-distinct-colors/
export const PRIMARY_CANDIDATE_COLORS_2020 = {
  sanders: '#fabebe', // Pink
  biden: '#aaffc3', // Mint
  warren: '#e6beff', // Lavender
  buttigieg: '#f58231', // Orange
  klobuchar: '#fffac8', // Beige
  steyer: '#9A6324', // Brown
  gabbard: '#ffe119', // Yellow
  bloomberg: '#3cb44b', // Green
  bennet: '#42d4f4', // Cyan
  booker: '#4363d8', // Blue
  castro: '#f032e6', // Magenta
  patrick: '#800000', // Maroon
  williamson: '#e6194B', // Red
  delaney: '#469990', // Teal
  yang: '#000075', // Navy
  trump: '#800000', // Maroon (again)
  uncommitted: '#333333', // Dark Gray
  writeIn: '#eeeeee', // Almost White
};
