import React from 'react';
import RaceFull from '../RaceFull';
import BoP from '../BOP';

export const slugify = (text) => {
  // https://gist.github.com/mathewbyrne/1280286
  return text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/[^\w-]+/g, '') // Remove all non-word chars
    .replace(/--+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, ''); // Trim - from end of text
};

export const useDefaultImg = (event) => {
  // Fallback if the image file for a candidate does not exist
  event.target.src = DEFAULT_CANDIDATE_IMAGE;
};

export const DEFAULT_CANDIDATE_IMAGE = '/data/imgs/candidates/default.png';

export const findRaceinMarix = (widget) => {
  const racesmatrix = {
    ussenate: 'usSenate',
    mnhouse_bop: 'bopMnHouse',
    mnsenate_bop: 'bopMnSenate',
    governor: 'governor',
    mnauditor: 'auditor',
    mnsos: 'secretaryOfState',
    mnag: 'attorneyGeneral',
  };
  return racesmatrix[widget];
};

export const buildRaceNodes = (item) => {
  if (!item) return null;
  if (item.type == 'BalanceOfPower') {
    return <BoP {...item} />;
  }
  return item.map((race) => {
    let level = raceLevel(race);

    return <RaceFull {...race} level={level} key={race.district} />;
  });
};
// Return a normalized race level
function raceLevel(race) {
  // Check the weird ones first
  if (race.name === 'Auditor') return 'mnAuditor';
  if (RegExp('^(Appeals Court)').test(race.name)) return 'appealsCourt';

  // These ones have an ID from the AP
  switch (race.apOfficeId) {
    case 'P':
      return 'president';
    case 'G':
      return 'governor';
    case 'A':
      return 'attorneyGeneral';
    case 'R':
      return 'secretaryOfState';
    case 'S':
      return 'usSenate';
    case 'H':
      return 'usHouse';
    case 'Z':
      return 'mnSenate';
    case 'Y':
      return 'mnHouse';
    case 'J':
      return 'mnSupremeCourt';
    default:
      return null;
  }
}

function ushouseracesFromPotlatch(config) {
  return config?.widgets
    .map((race) => {
      const mtch = race.widget.match(/\d+$/);
      const ret = mtch ? mtch[0] : null;
      return ret;
    })
    .filter((itm) => itm !== null);
}

export function findRacesToShow(electionData, config) {
  const houseRacesToShowArr = [];
  if (!electionData) return houseRacesToShowArr;
  electionData.usHouse.map((race) => {
    ushouseracesFromPotlatch(config).forEach((itm) => {
      if (race.name.includes(itm)) {
        houseRacesToShowArr.push(race);
      }
    });
  });
  return houseRacesToShowArr;
}
