import React, { useState } from 'react';
import Svg from '../Svg';
import {
  PARTY_CSS_CLASSES,
  PARTY_FULL_NAMES,
  PRIMARY_CANDIDATE_COLORS_2020,
} from '../config';
import { useDefaultImg } from '../util';
import PropTypes from 'prop-types';

const slugify = (text) => {
  // https://gist.github.com/mathewbyrne/1280286
  return text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/[^\w-]+/g, '') // Remove all non-word chars
    .replace(/--+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, ''); // Trim - from end of text
};

const RaceFull = ({
  candidates,
  name,
  precinctsReportingPct,
  compact = 2,
  source,
  addLabel,
  isPrimary,
}) => {
  const [hover, setHover] = useState({ isHover: false, hoveredCandidate: 0 });

  const handleCandidateMouseOver = (candidateId) => {
    setHover({ isHover: true, hoveredCandidate: candidateId });
  };

  const handleCandidateMouseOut = () => {
    setHover({ isHover: false, hoveredCandidate: 0 });
  };

  const getSortedCandidates = () => {
    const length = compact ? 2 : undefined;

    return candidates
      ?.sort(function (a, b) {
        if (a.votecount > b.votecount) {
          return -1;
        }
        if (a.votecount < b.votecount) {
          return 1;
        }
        return 0;
      })
      ?.slice(0, length);
  };

  const hasWinner = () => {
    return candidates?.some((candidate) => candidate.winner === true);
  };

  const getCandidateImage = (candidate) => {
    return (
      <img
        src={`/data/imgs/candidates/${slugify(candidate.name)}.jpg`}
        alt={candidate.name}
        data-polid={candidate.polid}
        className="results_tag_img"
        onError={useDefaultImg}
      />
    );
  };

  const buildCandidateNodes = () => {
    return getSortedCandidates()?.map((candidate) => {
      const tagClasses = `results_tag ${
        !isPrimary && PARTY_CSS_CLASSES[candidate.party]
      }`;
      const pctClasses = `results_pct ${
        candidate.winner ? 'results_pct_win' : ''
      } ${source}`;
      const candidateImage = getCandidateImage(candidate);
      const partyName = compact
        ? candidate.party
        : PARTY_FULL_NAMES[candidate.party] || candidate.party;

      let lastNameArray = candidate.name.split(' ');
      let lastName = lastNameArray[lastNameArray.length - 1].toLowerCase();
      let color = PRIMARY_CANDIDATE_COLORS_2020[lastName];

      const candidateBarStyle = {
        backgroundColor: color,
      };

      return (
        <li
          className={`results_item ${
            hover.hoveredCandidate === candidate.candidateId
              ? 'is-selected'
              : ''
          }`}
          onMouseOver={() => handleCandidateMouseOver(candidate.candidateId)}
          onFocus={() => handleCandidateMouseOver(candidate.candidateId)}
          onMouseOut={() => handleCandidateMouseOut()}
          onBlur={() => handleCandidateMouseOut()}
          key={candidate.candidateId}
        >
          <div>
            <div
              className="results_item_tag"
              style={isPrimary && candidateBarStyle}
            >
              <div
                className={tagClasses}
                style={isPrimary && candidateBarStyle}
              >
                {candidateImage}
                {!isPrimary && (
                  <span className="results_tag_party">{candidate.party}</span>
                )}
              </div>
            </div>
            <div className="results_stats_left">
              <h6 className="hdg hdg-6">{candidate.name}</h6>
              {!isPrimary && <div className="results_text">{partyName}</div>}
            </div>
          </div>
          <div className="results_stats_right">
            <div className={pctClasses}>
              {candidate.winner && <span>✔︎ </span>}
              {Math.round(candidate.votepct * 100)}%
            </div>
            <div className="results_text">
              {candidate.votecount.toLocaleString()}
            </div>
          </div>
        </li>
      );
    });
  };

  const buildCandidateBars = () => {
    return getSortedCandidates()?.map((candidate) => {
      let lastNameArray = candidate.name.split(' ');
      let lastName = lastNameArray[lastNameArray.length - 1].toLowerCase();
      let color = PRIMARY_CANDIDATE_COLORS_2020[lastName];

      const raceBarStyle = {
        width: candidate.votepct * 100 + '%',
        backgroundColor: color,
      };

      const raceBarClass = `graph_segment noPointer ${
        !isPrimary && PARTY_CSS_CLASSES[candidate.party]
      } ${
        hover.hoveredCandidate === candidate.candidateId ? 'is-selected' : ''
      } ${hover.isHover ? 'is-hover' : ''}`;

      return (
        <div
          onMouseOver={() => handleCandidateMouseOver(candidate.candidateId)}
          onFocus={() => handleCandidateMouseOver(candidate.candidateId)}
          onMouseOut={() => handleCandidateMouseOut()}
          onBlur={() => handleCandidateMouseOut()}
          key={candidate.candidateId}
          className={raceBarClass}
          style={raceBarStyle}
        >
          <span className="invisible">
            {`${candidate.name}: ${Math.round(candidate.votepct * 100)}%`}
          </span>
        </div>
      );
    });
  };

  const buildRaceTitle = () => {
    return (
      <h6 className="hdg hdg-6">
        {name}
        {addLabel && addLabel}
      </h6>
    );
  };

  const buildRaceGraph = () => {
    if (!compact) {
      return (
        <div className="race_graph">
          <div className="graph">{buildCandidateBars()}</div>
        </div>
      );
    }
  };

  const resultsClass = () => {
    return 'results results-compact';
  };

  // @todo if there are only a few results this prevents them from showing if less than 1%
  // use precinctReporting > 1 instead
  if (Math.round(precinctsReportingPct * 100) === 0) {
    return (
      <div
        className={`race race-full ${hasWinner() && 'has-winner'}`}
        data-testid="race-full"
      >
        <div className="race_header">
          <div className="split">
            <div className="split_left">{buildRaceTitle()}</div>
            <div className="split_right">
              {Math.round(precinctsReportingPct * 100)}% Reporting
            </div>
          </div>
        </div>
        {buildRaceGraph()}
        <div className="race_body">
          <p>Polls close at 8pm, initial results coming soon.</p>
        </div>
      </div>
    );
  }

  return (
    <div
      className={`race race-full ${hasWinner() && 'has-winner'}`}
      data-testid="race-full"
    >
      <div className="race_header">
        <div className="split">
          <div className="split_left">{buildRaceTitle()}</div>
          <div className="split_right">
            {Math.round(precinctsReportingPct * 100)}% Reporting
          </div>
        </div>
      </div>
      {buildRaceGraph()}
      <div className="race_body">
        {Math.round(precinctsReportingPct * 100) === 0 ? (
          <p>Polls close at 8pm, initial results coming soon.</p>
        ) : (
          <ol className={resultsClass()}>{buildCandidateNodes()}</ol>
        )}
      </div>
    </div>
  );
};

RaceFull.propTypes = {
  candidates: PropTypes.array.isRequired,
  name: PropTypes.string,
  isPrimary: PropTypes.bool,
  precinctsReportingPct: PropTypes.number.isRequired,
  compact: PropTypes.bool,
  source: PropTypes.string,
  addLabel: PropTypes.string,
};

export default RaceFull;
