import { useContext, useEffect, useState } from 'react';
import { VisibilityContext } from 'react-horizontal-scrolling-menu';

export function LeftArrow() {
  // Should disable arrow when it's already scrolled all the way to the left.
  // Currently doesn't work, because I had to change it when I updated
  // react-horizontal-scrolling-menu.
  const { isFirstItemVisible, scrollPrev } = useContext(VisibilityContext);

  const [disabled, setDisabled] = useState(isFirstItemVisible);
  useEffect(() => {
    setDisabled(isFirstItemVisible);
  }, [isFirstItemVisible]);

  return (
    <button
      className="scrollmenu-arrow-btn scrollmenu-left"
      disabled={disabled}
      onClick={() => scrollPrev()}
    >
      <img src="/img/back.svg" alt="Scroll left" />
    </button>
  );
}

export function RightArrow() {
  // Should disable arrow when it's already scrolled all the way to the right.
  // Currently doesn't work, because I had to change it when I updated
  // react-horizontal-scrolling-menu.
  const { isLastItemVisible, scrollNext } = useContext(VisibilityContext);

  const [disabled, setDisabled] = useState(isLastItemVisible);
  useEffect(() => {
    setDisabled(isLastItemVisible);
  }, [isLastItemVisible]);

  return (
    <button
      className="scrollmenu-arrow-btn scrollmenu-right"
      disabled={disabled}
      onClick={() => scrollNext()}
    >
      <img src="/img/forward.svg" alt="Scroll right" />
    </button>
  );
}
