import React from 'react';
import PropTypes from 'prop-types';

const BoP = ({ title, other, left, left_caucus, right, total, compact }) => {
  const democrats = left_caucus ? left + left_caucus : left;
  const pctLeft = Number((democrats / total) * 100).toFixed();
  const pctRight = Number((right / total) * 100).toFixed();
  const leftStyle = { width: `${pctLeft}%` };
  const rightStyle = { width: `${pctRight}%` };
  const candidateClass = compact ? 'candidate candidate-compact' : 'candidate';
  const candidateVotesClass = compact
    ? 'candidate_votes candidate_votes_inline'
    : 'candidate_votes';

  let candTagLeft;
  let candTagRight;

  if (!compact) {
    candTagLeft = (
      <div className="candidate_tag dem">
        <span className="candidate_tag_party">DEM</span>
      </div>
    );
    candTagRight = (
      <div className="candidate_tag gop">
        <span className="candidate_tag_party">GOP</span>
      </div>
    );
  }

  const democratCaveat = () => {
    if (!other || other < 1) {
      return '';
    }
    return (
      <div className="candidate_caveat">
        {`* includes ${other} independents`}
      </div>
    );
  };

  return (
    <div className="race">
      <div className="race_header">
        <h3 className="hdg hdg-6">{title}</h3>
      </div>
      <div className="race_graph race_graph-bop">
        <div className="graph graph-bop">
          <div style={leftStyle} className="graph_segment dem">
            <span className="invisible">{`Democrats: ${democrats}`}</span>
          </div>
          <div style={rightStyle} className="graph_segment gop">
            <span className="invisible">{`Republicans: ${right}`}</span>
          </div>
        </div>
      </div>
      <div className="race_body">
        <ol className="race_candidates">
          <li className={candidateClass}>
            {candTagLeft}
            <div className="candidate_stats">
              <div className={candidateVotesClass}>
                {democrats} Seats
                {democratCaveat()}
              </div>
            </div>
          </li>
          <li className={candidateClass}>
            {candTagRight}
            <div className="candidate_stats">
              <div className={candidateVotesClass}>{right} Seats</div>
            </div>
          </li>
        </ol>
      </div>
    </div>
  );
};

BoP.propTypes = {
  other: PropTypes.number,
  left: PropTypes.number.isRequired,
  left_caucus: PropTypes.number,
  right: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  compact: PropTypes.bool,
  title: PropTypes.string,
};

export default BoP;
